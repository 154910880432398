
import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import { useEffect, useState, } from 'react';
import { useMachine } from '@xstate/react';
import { promiseMachine, machine, machine2 } from './machine'

function App() {
  const [state, send] = useMachine(machine2, {
    actions: {
      'muteMicrophone': () => {
        alert('muteMicrophone')
      },
      'unmuteMicrophone': () => {
        alert('unmuteMicrophone')
      }
    }
  });

  useEffect(() => {

  }, []);

  console.log('state', state.nextEvents);
  console.log('sendd', send);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <div>
          {JSON.stringify({
            value: state.value,
            context: state.context,
          }, null, 2)}
        </div>

        {
          state.nextEvents.map((event) => {
            return <button onClick={() => {
              send(event)
            }}>{event}</button>

          })
        }

      </header>
    </div>
  );
}

export default App;
