import { createMachine } from "xstate";

export const promiseMachine = createMachine({
    /** @xstate-layout N4IgpgJg5mDOIC5QBED2UB0BBA7gQwGswBiAMTwBsLYACPWCsMABwG0AGAXUVGdVgCWAFwGoAdjxAAPRAFoAzAFYAHBmUAmACzqAjMoDsigDQgAnonX71GAGztFmmwcUBfFybSYsDJs2IAZVABXCBoAOVQBWDAObiQQPkERcUkZBFkdTR0MLM12G3VjMzl2DEUATn0bRQLXdxBPbB8WYgBlAFswKloAM1RUCFjJROFRCXi0jP19NS1dZxNzBBts+X0ddnK1uo90bHwiDFbGFlMA4NCIqJiuYf5RlInETRnFdn1lHULFi3Ls3U0yk02zc9TEAzgkk8dySY1ScmB-0U8nK+W+xXSylUmyqNUKbl2XgOYBhD3GoEmOkyGB0KnU7HkXyKS0y1gBQJB9Ua3hOzFJyXJ0jk8mU8gwhRRaOZiEU1nK6nKTkMBIae1whDAR15S149wF8IQyIw8sVCwx6nUqnWgOByq5auJRwAxngAE6Qflwp7pGw2Gl0hlMn4IRnWRzOUEuIA */
    id: "Dog",
    initial: "Awake",
    states: {
        Awake: {
            on: {
                "Falls asleep": "Asleep"
            },

            states: {
                Sleepy: {
                    on: {
                        "Loud Noise": "Scared"
                    }
                },
                Scared: {}
            },

            initial: "Sleepy"
        },
        Asleep: {
            on: {
                "Loud Noise": "Awake.Scared",
                "Smells food": "Awake.Sleepy"
            }
        }
    }
})


export const machine = createMachine({
    /** @xstate-layout N4IgpgJg5mDOIC5QHUCGAbA1gOjQSwBc8A7KAYgBkxUA3MAAgAkB7AWzAG0AGAXUVAAOzWITzNi-EAA9EAWgBs2ABwBOLvIBMAVgA0IAJ6IAjEewBmAOyr12gL629aLNgDyxeqnoB3DJjIBxMAJ6ACNUAGNMegALNk5eSSERInFJGQRZKz1DBCMuM2wLLXsHEGJmCDhJJ0xE4VFUpGk5My4tZTMtFUtdAzkLC3MLPO6i+0dfXFRRUjrksQkm9NkTFWUjbWzjLmwN601i0prXd08fLDmGxdB0jY1sFQAWFQ0jJSKt3I01jQGzR564xAxxq9DAxEqEEuKWuzQy2kGSk6o16OQ0XHuWg0-0BJSAA */
    id: 'Walk',
    initial: 'Waiting',
    states: {
        Waiting: {
            on: {
                "Leave Home": "On a walk"
            }
        },

        "On a walk": {
            on: {
                "Get back home": "Walk ended"
            }
        },
        "Walk ended": {
            type: 'final',
        }
    }
})


export const machine2 = createMachine({
    /** @xstate-layout N4IgpgJg5mDOIC5QGECGAbdACAsqgFgJYB2YAdDoQMYBOA9gA752kUCuALpAMQCqxAW06QA2gAYAuolAM6sQh0ItpIAB6IAtACYAzAE4yAdgAsxgIzGxANi0BWADQgAnoi0AOMQF9PjtJlwEJOSUtIzMrPxCXBDcOMLiUkggsvKKyknqCBq2YoZkZjpuVoZmboYOzq5WYmR61Xo65d6+GNh4RKwAaoQQYHRkAMrMAO4kUFgAbj193EN0w5PTdAkqKQpKxCqZZrYGerZuem7GjRUuCLoGNrbNIH5tgV1LZAASPWOLvXTcb72ffSskms0psMpodmQtKYLNY7I5zo0DFoxDozHZvD4QMQ6L14El7gEOmBVnJ1ulQJkNNUdPlCsVSuV4eCamI9IYrLZrrcCe0ghRqPQmCxiUDSSCtppbFLaUUSmUzohDHlLLoTk1MTzHsEBWFhexoiTUhsJVkdMY3GRbOYxDtrkyEGYrMZamJVadua1CXyQoLwuRIsIIIayaCKZodAdLbYtIYzad7fpnXpYTcNZ7eU8vsHxWCslYaVDzJY7ZUHTkyPKPf4M+Rul9BiMPlMs6KjeS1JpndTbLa4aXdBb3Wnq1qyHW+q93sRxs2+tnjbmdloKzoxLk3GiFQhEZCUZuMZ4gA */
    id: 'Call Mahine',
    type: 'parallel',
    states: {
        Microphone: {
            initial: "Muted",

            states: {
                Muted: {
                    entry: 'Mute microphone',
                    on: {
                        Unmuted: {
                            actions: 'unmuteMicrophone',
                            target: "Unmuted",
                        }
                    }
                },
                Unmuted: {
                    entry: ['Unmue microphone'],
                    on: {
                        Muted: {
                            actions: 'muteMicrophone',
                            target: "Muted",
                        }
                    }
                }
            }
        },

        Video: {
            states: {
                "Showing video": {
                    on: {
                        "Show video": "Hiding video"
                    }
                },
                "Hiding video": {
                    on: {
                        "Hide video": "Showing video"
                    }
                }
            },

            initial: "Showing video"
        }
    }
})